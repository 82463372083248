import React, { useContext } from 'react'
import styled from "styled-components";
import breakpoints from "../../../utils/breakpoints";
import { navigate } from "gatsby"

export const typename = 'Set_Modules_CompanyCard'

const Wrap = styled.div`
  button {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    &:hover {
      &::after {
        opacity: 1;
      }
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: -2rem;
      left: -3rem;
      bottom: -2rem;
      right: 0;
      background-color: #2b2a27;
      opacity: 0;
      transition: opacity 0.5s cubic-bezier(0.6, 0, 0.25, 1);
    }

    h2 {
      z-index: 1;
      font-size: 3.9rem;
      line-height: 1.2em;
      font-weight: 300;
      padding:0;
      margin:0;
    }
    .sector,
    .region {
      z-index: 1;
      font-size: 1.4rem;
      line-height: 1.28571em;
      font-weight: 200;
      color: var(--brand-light-gray);
      span {
        font-size: 1.4rem;
        font-weight: 400;
      }
    }
  }
`;

const CompanyCardBlock = ({ company, onClose }) => {
  const clickCompany = () => {
    const regex = /^\/(?:([a-z]{2})(?=\/|$))?/
    const locale = window.location.pathname.match(regex)?.[1]

    const targetPath = `${locale ? '/' + locale : ''}/portfolio?id=${company.title}`
    const url = new URL(window.location.href)

    if (url?.pathname.includes('portfolio')) {
      window.location = targetPath;
    } else{
      navigate(targetPath, { replace: true })
    }
  }

  return (
    <Wrap>
      <button
        type="button"
        onClick={() => clickCompany()}
      >
        <h2 className="h3">{company.title}</h2>
        {company?.sectors && (
          <span className="sector">
            <span className="font-medium">Sector: </span> 
            {company.sectors?.title}
          </span>
        )}
        {company?.region && (
          <span className="region">
            <span className="font-medium">Country: </span>
            {company.region.title}
          </span>
        )}
      </button>
    </Wrap>
  )
}

export default CompanyCardBlock
