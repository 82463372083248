import React, { useEffect, useLayoutEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {createGlobalStyle } from "styled-components";
import styled from "styled-components";
import "normalize.css";
import breakpoints from "../../utils/breakpoints";

import data from "../../../data/navigation.json";

import Header from "../header";
import Footer from "../footer";
import CookieBanner from "../cookie-banner";
import {FontFaces} from "../../components/typography";

const GlobalStyles = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: border-box;
  }

  html {
    -webkit-font-smoothing: antialiased;
    font-size: 62.5%;
    /* scroll-behavior: smooth; */

    --font-primary: 'Post Grotesk', sans-serif;
    --font-secondary: 'Magazine Grotesque', serif;

    --brand-black: #1A1818;
    --brand-white: #f7fbff;
    --brand-blue: #224466;
    --brand-blue-gray: #78909A;
    --brand-yellow: #FBF4D0;
    --brand-teal: #5BADB3;
    --brand-gray: #333333;
    --brand-light-gray: #F5FAFA;
    --brand-medium-gray: #807E7E;

    --grid-width: 100vw;
    --grid-padding: 2rem;
    --grid-gutter: 1rem;
    --grid-column-count: 4;

    --z-top: 10;
    --z-cookie-banner: 9;
    --z-overlay: 8;
    --z-nav: 7;

    --ease-soft: cubic-bezier(0.000,0.000,0.002,1.000);

    @media ${breakpoints.tabletSm} {
      --grid-padding: 4.6rem;
      --grid-column-count: 12;
      --grid-gutter: 2rem;
    }

    @media ${breakpoints.tablet} {
      --grid-padding: 6rem;
    }

    @media ${breakpoints.desktop} {
      --grid-padding: 7.4rem;
      --grid-gutter: 3rem;
    }
  }

  body {
    font-family: var(--font-primary);
    font-size: 1.6rem;
    line-height: 1.375;
    color: var(--brand-gray);
    font-weight: 300;
    background-color: var(--brand-white);
    letter-spacing: 0.01em;

    @media ${breakpoints.desktop} {
      font-size: 1.9rem;
      line-height: 1.3157894737;
      letter-spacing: 0.02em;
    }
  }

  main {
    min-height: calc(100vh + 0.1rem);
  }

  button {
    -webkit-appearance: none;
    outline: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    font-weight: 300;
    color: inherit;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  p {
    margin: 0 0 1.5em 0;
  }

  .overlay {
    position: fixed;
    z-index: var(--z-top);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 4.6rem 2rem;
    overflow: auto;

    @media ${breakpoints.tabletSm} {
      padding: 4.6rem 5rem;
    }

    @media ${breakpoints.tablet} {
      padding: 4.6rem 5.2rem;
    }

    @media ${breakpoints.desktop} {
      padding: 5.6rem;
    }
  }

  .ReactModal__Content {
    outline: none;
  }

  .modal-open {
    overflow: hidden;
  }
`;

const Main = styled.main`
  padding-top: ${props => (props.mobiletheme === "light" ? props.headeroffset : "0")}px;
  @media ${breakpoints.tabletLg} {
    padding-top: ${props => (props.theme === "light" ? props.headeroffset : "0")}px;
  }
`;

export default function Layout({ location, children, theme, mobileTheme, themeLogo, seo, nav, footer_nav, global, search, team, portfolio_companies }) {
  const [headerOffset, setHeaderOffset] = useState(0);

  useLayoutEffect(() => {
    let header = document.querySelector('header');
    if (header) {
      setHeaderOffset(header.offsetHeight);
    }
  }, []);

  useEffect(() => {
    if ("ontouchstart" in document) {
      document.documentElement.classList.add("is-touch");
    } else {
      document.documentElement.classList.add("is-not-touch");
    }
    let header = document.querySelector('header');
    const onResize = () => {
      if (header) {
        setHeaderOffset(header.offsetHeight);
      }
    }
    window.addEventListener("resize", onResize);
    return () => {
        window.removeEventListener("resize", onResize);
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta property="og:type" content="Website" />
        <meta property="og:title" content="SoftBank Latin America Fund" />
        <meta
          property="og:image"
          content="https://www.latinamericafund.com/images/og-image-1.jpg"
        />
        <meta property="og:url" content="https://www.latinamericafund.com/" />
        <meta
          property="og:description"
          content="SoftBank created the $5B SoftBank Latin America Fund to partner with the companies and entrepreneurs defining the future of the region."
        />
      </Helmet>

      <GlobalStyles />
      <FontFaces />
      <Header
        theme={theme}
        mobileTheme={mobileTheme}
        seo={seo}
        nav={nav}
        themelogo={themeLogo}
        primaryLinks={data.primaryLinks}
        secondaryLinks={data.secondaryLinks}
        location={location}
        search={search}
        team={team}
        portfolio_companies={portfolio_companies}
      />
      <CookieBanner />
      <Main id="main" theme={theme} mobiletheme={mobileTheme} headeroffset={headerOffset}>{children}</Main>
      <Footer
        global={global}
        footer_nav={footer_nav}
        primaryLinks={data.primaryLinks}
        secondaryLinks={data.secondaryLinks}
        // linkedInUrl={data.footer.linkedInUrl}
        // twitterUrl={data.footer.twitterUrl}
        // addressLine1={data.footer.addressLine1}
        // addressLine2={data.footer.addressLine2}
        // addressLine3={data.footer.addressLine3}
      />
    </>
  );
}
